import { getLangObj } from "../../lang/loadLanguage";
import { ResponsiveImage } from "../ResponsiveImage";

export interface CategoryRelatedItemProps {
    Url: string;
    Title: string;
    // Count: number;
    // Image: string;
    // ImageWidth: number;
    // ImageHeight: number;
}

export const CategoryRelatedItem = (props: CategoryRelatedItemProps) => {    
    return (
        <a href={props.Url} class="related-category">
            <div>
                <div class="category-title">{props.Title}</div>
                {/* <div class="category-description">{} {getLangObj().itemCountText(props.Count)}</div> */}
            </div>
        </a>
    );
}

export default CategoryRelatedItem;