import { createSignal, onCleanup, onMount } from "solid-js";
import type { JSX } from "solid-js";

const ScrollNotification = (props: {observerSelector: string, children: JSX.Element}) => {
    const [showNotification, setShowNotification] = createSignal(false);
    let observer: IntersectionObserver;
  
    const onIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach(entry => {
        setShowNotification(entry.boundingClientRect.top < 0 && entry.intersectionRatio <= 0);
      });
    };
  
    onMount(() => {
      observer = new IntersectionObserver(onIntersect, {
        threshold: [0]
      });
  
      const observedElement = document.querySelector(props.observerSelector);
  
      if (observedElement) {
        observer.observe(observedElement);
      } else {
        console.error("ScrollNotification: Element to observe not found");
      }
    });
  
    onCleanup(() => {
      if (observer) {
        observer.disconnect();
      }
    });
  
    return (
      <button classList={{ btn: true, "btn-primary": true, "scroll-notification": true, show: showNotification() }} onclick={() => document.querySelector(".product-list-container-section")?.scrollIntoView({ behavior: "smooth" })}>
          {props.children}
      </button>
    );
  };
  
  export default ScrollNotification;
  