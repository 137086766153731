import { For, Show, type Accessor, type Setter } from "solid-js";
import IconCancel from "~icons/my/cancel";
import FilterListItem from "./FilterListItem";
import type { AttributeInCategoryDto, AttributeValueInCategoryDto, ProductGroupListDto } from "../../backend";
import { produce, type SetStoreFunction } from "solid-js/store";
import { Slider } from '@ark-ui/solid'
import "./ProductListSidebar.css";
import { getLangObj } from "../../lang/loadLanguage";
import type { CreateQueryResult } from "@tanstack/solid-query";

export interface FilterState {
    selectedCategories: { ID: number; Name: string; }[];
    //selectedAttributeValues: AttributeValue2[];
    selectedAttributes: {attribute: AttributeInCategoryDto, allowedValues: AttributeValueInCategoryDto[], allowUnassigned: boolean}[];
    selectedPriceRange: {min: number | null, max: number | null};
}
export const defaultFilter = () => ({ selectedCategories: [], selectedAttributes: [], selectedPriceRange: {min: null, max: null} } as FilterState);

const ProductListSidebar = (props: {
    setIsFilterSidebarVisible: Setter<boolean>,
    isFilterActive: Accessor<boolean>,
    attributes: AttributeInCategoryDto[],
    categories: { ID: number; Name: string; }[], 
    productsQuery: CreateQueryResult<ProductGroupListDto, Error>,
    filterState: FilterState, 
    setFilterState: SetStoreFunction<FilterState>,
    categoryProductCount: number;
}) => {

    const loc = getLangObj().ProductListSidebar;
    const minPrice = () => Math.floor((props.productsQuery.data?.MinPrice ?? 0) / 10) * 10;
    const maxPrice = () => Math.ceil((props.productsQuery.data?.MaxPrice ?? 0) / 10) * 10;
  
  return <>
    <div id="product-list-sidebar-overlay" class="product-list-sidebar-overlay" onClick={() => props.setIsFilterSidebarVisible(false)}></div>
    <div id="product-list-sidebar" class="product-list-sidebar">
        <div class="product-list-sidebar-header">
            <div class="product-list-sidebar-title">
                {loc["Filter"]}
            </div>

            <button id="mobile-filters-button-close" onclick={() => props.setIsFilterSidebarVisible(false)} type="button" class="btn btn-sm btn-white" aria-label={loc["Close"]}>
                <IconCancel class="icon" />
            </button>
        </div>

        <div class="product-list-filters-list">
        
            {
                props.categories.length > 0 &&
                <FilterListItem
                    title={loc["By category"]}
                    items={props.categories.map(c => ({
                        label: c.Name, 
                        isSelected: typeof props.filterState.selectedCategories.find(sc => sc.ID == c.ID) != "undefined", 
                        onSelectChanged: (isSelected: boolean) => {
                            if(isSelected)
                            {
                                props.setFilterState("selectedCategories", props.filterState.selectedCategories.length, c) 
                            } else {
                                props.setFilterState("selectedCategories", props.filterState.selectedCategories.filter(sc => sc.ID != c.ID)) 
                            }
                        }
                    }))}
                    isOpenByDefault={true}
                />
            }

            <For each={props.attributes}>
                {(attribute) => {
                    const attributeProductCounts = () => props.productsQuery.data?.ProductCountByAttribute.find(a => a.AttributeID == attribute.ID);
                    return attribute.Values.length > 0 && <FilterListItem
                    title={attribute.LangName}
                    isOpenByDefault={false}
                    items={[...attribute.Values.map((value) => ({
                        label: value.LangValue,
                        isSelected: props.filterState.selectedAttributes.some(sa => 
                            sa.attribute.ID == attribute.ID && sa.allowedValues.some(av => av.ID == value.ID)),
                        productCount: attributeProductCounts()?.ProductCountByValue.find(av => av.AttributeValueID == value.ID)?.ProductCount,
                        onSelectChanged: (isSelected: boolean) => {
                            props.setFilterState(produce(draft => {
                                const attributeIndex = draft.selectedAttributes.findIndex(sa => sa.attribute.ID == attribute.ID);
                                
                                if (isSelected) {
                                    if (attributeIndex > -1) {
                                        // Attribute is already selected, just add the value to its allowedValues
                                        draft.selectedAttributes[attributeIndex].allowedValues.push(value);
                                    } else {
                                        // Attribute is not selected yet, add it with the value
                                        draft.selectedAttributes.push({
                                            attribute: attribute,
                                            allowedValues: [value],
                                            allowUnassigned: false // Adjust as necessary
                                        });
                                    }
                                } else {
                                    if (attributeIndex > -1) {
                                        const valueIndex = draft.selectedAttributes[attributeIndex].allowedValues.findIndex(av => av.ID == value.ID);
                                        if (valueIndex > -1) {
                                            draft.selectedAttributes[attributeIndex].allowedValues.splice(valueIndex, 1);
                
                                            // If no more allowedValues left, remove the attribute from selectedAttributes
                                            if (draft.selectedAttributes[attributeIndex].allowedValues.length === 0) {
                                                draft.selectedAttributes.splice(attributeIndex, 1);
                                            }
                                        }
                                    }
                                }
                            }));
                        },
                    })), {
                        label: loc["unspecified"],
                        isSelected: props.filterState.selectedAttributes.some(sa => 
                          sa.attribute.ID == attribute.ID && sa.allowUnassigned),
                        productCount: attributeProductCounts()?.ProductCountUnassigned,
                        onSelectChanged: isSelected => {
                          props.setFilterState(produce(draft => {
                            const attributeIndex = draft.selectedAttributes.findIndex(sa => sa.attribute.ID == attribute.ID);
                  
                            if (attributeIndex > -1) {
                              draft.selectedAttributes[attributeIndex].allowUnassigned = isSelected;
                            } else {
                              // If the attribute is not found (which means it's not selected and has no allowedValues yet)
                              // add it with allowUnassigned set to true, but without allowedValues
                              draft.selectedAttributes.push({
                                attribute: attribute,
                                allowedValues: [],
                                allowUnassigned: isSelected
                              });
                            }
                          }));
                        },
                      }]}
                    />
                }}
            </For>

            <FilterListItem title={loc["Price"]} isOpenByDefault={false} class="product-filter-price">
                <div class="filter-price-flex">
                    <input id="filter-price-input-min" type="number" min={minPrice()} value={props.filterState.selectedPriceRange?.min ?? minPrice()} onChange={e => props.setFilterState("selectedPriceRange", "min", Number(e.target.value))} class="form-control" aria-label={loc["Minimum price"]} />

                    <div class="filter-price-input-separator"></div>

                    <input id="filter-price-input-max" type="number" max={maxPrice()} value={props.filterState.selectedPriceRange?.max ?? maxPrice()} onChange={e => props.setFilterState("selectedPriceRange", "max", Number(e.target.value))} class="form-control" aria-label={loc["Maximum price"]} />

                    <button type="button" class="btn filter-price-btn">Ok</button>
                </div>
                
                <Slider.Root
                    class="slider slider-horizontal"
                    min={minPrice()}
                    max={maxPrice()}
                    step={10}
                    minStepsBetweenThumbs={10}
                    value={[props.filterState.selectedPriceRange.min ?? minPrice(), props.filterState.selectedPriceRange.max ?? maxPrice()]}
                    onValueChangeEnd={priceRange => { 
                        props.setFilterState("selectedPriceRange", produce(statePriceRange => {
                            statePriceRange.min = Math.max(minPrice(), priceRange.value[0]);
                            statePriceRange.max = Math.min(maxPrice(), priceRange.value[1]);
                        }))}
                    }
                >
                    <Slider.Control>
                    <Slider.Track class="slider-track">
                        <Slider.Range class="slider-selection" />
                    </Slider.Track>
                    <Slider.Thumb class="slider-handle min-slider-handle round" index={0} />
                    <Slider.Thumb class="slider-handle max-slider-handle round" index={1} />
                    </Slider.Control>
                </Slider.Root>
            </FilterListItem>

        </div>

        <button type="button" class="btn btn-sm btn-filter" onClick={() => props.setIsFilterSidebarVisible(false)}>
            {loc["Apply filter"]}
            <Show when={!props.productsQuery.isPlaceholderData}>
                {" "}({props.productsQuery.data?.MatchingProductCount})
            </Show>
        </button>
    </div>
  </>;
};

export default ProductListSidebar;