import { getLangObj } from "../../lang/loadLanguage";
import { ResponsiveImage } from "../ResponsiveImage";

export interface CategoryListItemProps {
    Url: string;
    Title: string;
    Count: number;
    Image: string;
    ImageWidth: number;
    ImageHeight: number;
}

export const CategoryListItem = (props: CategoryListItemProps) => {    
    return (
        <a href={props.Url} class="category">
            <div class="img-wrapper">
                <ResponsiveImage
                    alt={props.Title}
                    src={props.Image}
                    srcWidth={props.ImageWidth}
                    srcHeight={props.ImageHeight}
                    renderWidths={[95, 115, 135, 180]} 
                    renderSizeHints={[
                        "(max-width: 576px) calc((100vw - 18px + 2px)*0.5 - 18px - 2rem - 1.2em)",
                        "(max-width: 768px) calc((min(100vw, 558px) - 18px + 2px)*0.3333333333 - 18px - 2rem - 1.2em)",
                        "(max-width: 992px) calc((min(100vw, 738px) - 18px + 2px)*0.25 - 18px - 2rem - 1.2em)",
                        "(max-width: 1200px) calc((min(100vw, 978px) - 18px + 2px)*0.1666666667 - 18px - 2rem - 1.2em)",
                        "calc((min(100vw, 1218px) - 18px + 2px)*0.1666666667 - 18px - 2rem - 1.2em)"]}
                    loading="eager"
                    />
            </div>
            <div>
                <div class="category-title">{props.Title}</div>
                <div class="category-description">{} {getLangObj().itemCountText(props.Count)}</div>
            </div>
        </a>
    );
}

export default CategoryListItem;