import NavLink from "../../layouts/NavLink";
import Placeholder from "../Placeholder";

export const ProductListItemPlaceholder = () => {
    return (
        <div class="product-list-item">
            <article>
                <div>
                    <NavLink class="product-img-wrapper" style="height: 130px;">
                        <Placeholder style="width: 100%; height: 80%;" />
                    </NavLink>

                    <NavLink>
                        <h2 class="product-list-item-title"><Placeholder style="width: 12em;" /></h2>
                        <div class="product-list-item-category"><Placeholder style="width: 12em;" /></div>
                    </NavLink>
                </div>

                <div>
                    <NavLink>
                        <div class="product-list-item-price">
                            <div>
                                <span class="price">
                                    <Placeholder style="width: 5em;" />
                                </span>
                                <span class="vat"><Placeholder style="width: 7em;" /></span>
                            </div>
                            <div>
                                <strong class="non-vat-price">
                                    <Placeholder style="width: 5em;" />
                                </strong>
                                <span class="vat"><Placeholder style="width: 7em;" /></span>
                            </div>
                        </div>
                    </NavLink>

                    <div class="product-list-item-footer">
                        <a href="#" class="product-list-item-stock">
                            <Placeholder style="width: 7em;" />
                        </a>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default ProductListItemPlaceholder;