interface CheckboxProps {
  title: string;
  subtitle: string | undefined;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  labelClass?: string;
}

function Checkbox(props: CheckboxProps) {
  return (
    <div class="form-group-checkbox">
      <div class="form-checkbox">
        <label>
          <input type="checkbox" checked={props.isChecked} onChange={e => props.onChange(e.target.checked)} />
          <span class={`form-checkbox-name ${props.labelClass || ''}`}>{props.title}</span>
          {props.subtitle && <span class="form-checkbox-subtitle">({props.subtitle})</span>}
        </label>
      </div>
    </div>
  );
}

export default Checkbox;