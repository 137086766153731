import type { SetStoreFunction } from "solid-js/store";
import { defaultFilter, type FilterState } from "./ProductListSidebar";
import { For, Show, type Accessor } from "solid-js";
import IconCancel from "~icons/my/cancel";
import { getLangObj } from "../../lang/loadLanguage";

const FilterListControls = (props: {filterState: FilterState, setFilterState: SetStoreFunction<FilterState>, isFilterActive: Accessor<boolean>}) => {
  const loc = getLangObj().FilterListControls;
  const formatCurrency = getLangObj().formatCurrency;
  
  return (<>
    <div class="filters-cancel-list">
        <Show when={props.isFilterActive()}>
            <button 
                onclick={() => {props.setFilterState(defaultFilter());}} 
                type="button" 
                class="btn btn-bordered btn-sm filter-button-cancel-all">
                    {loc["Cancel filter"]}
            </button>
        </Show>

        <For each={props.filterState.selectedCategories}>
            {selectedCategory => (
                <button 
                    onclick={() => props.setFilterState("selectedCategories", props.filterState.selectedCategories.filter(sc => sc.ID !== selectedCategory.ID))} 
                    type="button"
                    class="btn btn-bordered btn-sm btn-icon-right">
                    {selectedCategory.Name} <IconCancel class="icon" />
                </button>
            )}
        </For>

        <For each={props.filterState.selectedAttributes}>
            {(attribute) => (
                <>
                    <For each={attribute.allowedValues}>
                        {(attributeValue) => (
                            <button 
                                onclick={() => {
                                    props.setFilterState(prevState => {
                                        return {
                                            ...prevState,
                                            selectedAttributes: prevState.selectedAttributes.map(sa => {
                                                if (sa.attribute.ID === attribute.attribute.ID) {
                                                    // Filter out the clicked attribute value
                                                    const filteredValues = sa.allowedValues.filter(av => av.ID !== attributeValue.ID);
                                                    return { ...sa, allowedValues: filteredValues };
                                                }
                                                return sa;
                                            }).filter(sa => sa.allowedValues.length > 0 || sa.allowUnassigned) // Remove the attribute if no values left
                                        };
                                    });
                                }} 
                                type="button"
                                class="btn btn-bordered btn-sm btn-icon-right">
                                {attributeValue.LangValue} <IconCancel class="icon" />
                            </button>
                        )}
                    </For>
                    {attribute.allowUnassigned &&
                    <button 
                        onclick={() => {
                            props.setFilterState(prevState => {
                                return {
                                    ...prevState,
                                    selectedAttributes: prevState.selectedAttributes.map(sa => {
                                        if (sa.attribute.ID === attribute.attribute.ID) {
                                            return { ...sa, allowUnassigned: false };
                                        }
                                        return sa;
                                    }).filter(sa => sa.allowedValues.length > 0 || sa.allowUnassigned) // Remove the attribute if no values left
                                };
                            });
                        }} 
                        type="button"
                        class="btn btn-bordered btn-sm btn-icon-right">
                        {attribute.attribute.LangName}: {loc["unspecified"]} <IconCancel class="icon" />
                    </button>
                    }
                    </>
                )}
            </For>


        <Show when={props.filterState.selectedPriceRange.min != null || props.filterState.selectedPriceRange.max != null}>
            <button 
                onclick={() => props.setFilterState("selectedPriceRange", defaultFilter().selectedPriceRange)} 
                type="button"
                class="btn btn-bordered btn-sm btn-icon-right">
                <Show when={props.filterState.selectedPriceRange.min != null}>
                    {loc["from"]} {formatCurrency(props.filterState.selectedPriceRange.min)} {" "}
                </Show>
                <Show when={props.filterState.selectedPriceRange.max != null}>
                    {loc["to"]} {formatCurrency(props.filterState.selectedPriceRange.max)}
                </Show>
                <IconCancel class="icon" />
            </button>
        </Show>
    </div>
  </>);
};

export default FilterListControls;