import { type Accessor } from "solid-js";
import IconPaginationChevronRight from "~icons/my/pagination-chevron-right";
import IconPaginationChevronLeft from "~icons/my/pagination-chevron-left";
import { getLangObj } from "../../lang/loadLanguage";

const getPageUrl = (categorySlug: string, targetPage: number) => {
    if(targetPage === 1)
      return `/${categorySlug}`;
    else
      return `/${categorySlug}/${targetPage}`;
}

export const Pagination = (props: {categorySlug: string, currentPage: Accessor<number>, totalPages: Accessor<number>, goToPage: (page: number, scrollIntoView: boolean) => void}) => {
  const loc = getLangObj().Pagination;

  return (
    <nav>
      <ul class="pagination">
        <li>
          <a class="btn btn-bordered" aria-label={loc["Previous page"]} href={props.currentPage() > 1 ? getPageUrl(props.categorySlug, props.currentPage() - 1) : ""} onClick={(e) => {e.preventDefault(); props.goToPage(props.currentPage() - 1, true)}}>
            <IconPaginationChevronLeft />
          </a>
        </li>
        {(() => {
          let threeDotsRendered = false;
          const items = [];
          for (let i = 1; i <= props.totalPages(); i++) {
            if (
              i === 1 ||
              (props.currentPage() <= 3 && i <= 3) ||
              (props.currentPage() >= props.totalPages() - 2 && i >= props.totalPages() - 2) ||
              (props.currentPage() - 1 <= i && i <= props.currentPage() + 1) ||
              i === props.totalPages() ||
              props.totalPages() <= 5
            ) {
              threeDotsRendered = false;
              items.push(
                <li class={i === props.currentPage() ? "active" : ""}>
                  <a class="btn btn-bordered" href={getPageUrl(props.categorySlug, i)} onClick={(e) =>{ e.preventDefault(); props.goToPage(i, true)}}>
                    {i}
                  </a>
                </li>
              );
            } else {
              if (!threeDotsRendered) {
                threeDotsRendered = true;
                items.push(
                  <li class="placeholder">
                    ...
                  </li>
                );
              }
            }
          }
          return items;
        })()}
        <li>
          <a class="btn btn-bordered" aria-label={loc["Next page"]} href={props.currentPage() < props.totalPages() ? getPageUrl(props.categorySlug, props.currentPage() + 1) : ""} onClick={(e) => {e.preventDefault(); props.goToPage(props.currentPage() + 1, true)}}>
            <IconPaginationChevronRight class="icon" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;