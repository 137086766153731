import { createSignal, For, Show } from 'solid-js';
import IconChevronRight from "~icons/my/chevron-right";
import Checkbox from './Checkbox.tsx';
import type { JSX } from 'astro/jsx-runtime';
import { getLangObj } from '../../lang/loadLanguage.ts';

interface FilterCheckboxProps {
  title: string;
  items: { label: string; productCount?: number | undefined; isSelected: boolean; onSelectChanged: (isSelected: boolean) => void; }[];
  isOpenByDefault: boolean;
}

interface FilterChildrenProps {
  title: string;
  children: JSX.Element;
  isOpenByDefault: boolean;
  class?: string;
}

function FilterListItem(props: FilterCheckboxProps | FilterChildrenProps) {
  const locale = getLangObj().locale;
  const [open, setOpen] = createSignal(props.isOpenByDefault);

  return (
    <div class={"product-list-filter " + ("class" in props ? props.class : "")}>
      <div
        classList={{"product-list-filter-heading": true, "active": open()}}
        aria-expanded={open()}
        onClick={() => setOpen(!open())}
      >
        {props.title}
        <Show when={(props as FilterCheckboxProps).items !== undefined}>
          {"items" in props && <span class="faded">{props.items.length}</span>}
        </Show>
        <IconChevronRight class="icon" />
      </div>
        <div classList={{"product-list-filter-content": true, "open": open()}}>
        {"items" in props && 
            <For each={props.items}>
              {(item) => <Checkbox title={item.label} subtitle={item.productCount?.toLocaleString(locale)} isChecked={item.isSelected} onChange={item.onSelectChanged} />}
            </For>}
          {"children" in props && props.children}
        </div>
      </div>
  );
}

export default FilterListItem;